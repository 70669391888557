@import "../../../global.scss";

.text {
  color: $plainFontColor;

  position: relative;
  top: 30%;
  text-align: left;
  transform: translate(5%, 0%);

  .header {
    padding: 20px 0px;

    font-size: 80px;
    font-weight: 700;
  }

  .description {
    padding: 0px 15px;

    // creates left border
    border-left: 4px solid $mainColor;
    height: 100%;

    inline-size: 70%;
    overflow: wrap;
    font-size: 25px;
    font-weight: 500;

    span {
      color: $mainColor;
    }
  }

  .projects-button {
    border: none;
    background-color: $mainColor;

    margin-top: 20px;
    padding: 10px 15px;
    border-radius: 20px;
    width: 150px;

    color: $plainFontColor;
    font-family: inherit;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;

    transition: 0.2s ease;

    // refers to Link component ('Link' does not work)
    a {
      text-decoration: none;
      color: $plainFontColor;
    }
  }

  .projects-button:hover {
    transform: translateY(-2px);
  }
}

@media (max-width: 1100px) {
  .text {
    top: 5%;
    text-align: center;
    transform: translate(0%, 0%);

    .description {
      inline-size: 100%;
      padding: 0px 0px;
      border-left: 0px solid $mainColor;

      inline-size: auto;
      margin-left: 5%;
      margin-right: 5%;
    }
  }
}

@media (max-width: 500px) {
  .text {
    .header {
      font-size: 50px;
    }
    .description {
      font-size: 20px;
    }
  }
}
