@import "../../global.scss";

.project-details {
  background-color: white;
  display: flex;

  scroll-snap-type: none;
  // height: auto;
  min-height: fit-content;

  .left {
    flex: 0.5;
    padding-left: 8%;
    padding-right: 7%;
    margin-top: 40px;
    color: $mainColor;

    .back {
      padding-top: 5px;
      // padding-bottom: 10px;
      text-decoration: none;
      color: black;
      overflow: wrap;

      background-image: linear-gradient(
        to right,
        $mainColor,
        $mainColor 50%,
        #000 50%
      );

      background-size: 200% 100%;
      background-position: -100%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all 0.3s ease-in-out;
    }

    .back:before {
      content: "";
      background: $mainColor;
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 0;
      height: 3px;
    }

    .back:hover {
      background-position: 0;
    }

    .back:hover::before {
      width: 100%;
    }
  }

  .tags {
    margin-top: 10px;
    display: flex;
  }

  .title {
    color: $mainColor;
    padding-top: 20px;
  }

  .header {
    color: $mainColor;
    font-size: 18px;
    padding-top: 10px;
  }

  .summary {
    display: flex;
    padding-bottom: 10px;

    .summary-left {
      padding-right: 40px;

      .bullet-text {
        color: $backgroundColor;
        padding-top: 5px;
        font-size: 16px;
      }
    }

    .summary-right {
      ul {
        color: $backgroundColor;
        list-style-type: none;
        padding: 0;
        padding-top: 5px;
        margin: 0;

        li {
          padding-bottom: 3px;
        }
      }
    }
  }

  .github-button {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 20px;

    border: none;
    background-color: $mainColor;

    padding: 10px 15px;
    border-radius: 20px;
    width: 200px;

    color: $plainFontColor;
    font-family: inherit;
    font-size: 15px;
    text-decoration: none;

    transition: 0.2s ease-in-out;

    a {
      text-decoration: none;
      color: $plainFontColor;
    }
  }

  .github-button:hover {
    opacity: 0.9;
    transform: translateY(-2px);
  }
}

.right {
  flex: 0.5;
  padding-top: 5%;
  padding-right: 8%;

  .project-image {
    display: block;
    margin-left: 0;

    overflow: hidden;

    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-7%, -50%);
  }
}

@media (max-width: 1100px) {
  .project-details {
    .left {
      flex: 1;
      margin-top: 40px;
    }

    .right {
      flex: 0;
      padding-right: 0%;

      .project-image {
        display: none;
      }
    }
  }
}

@media (max-height: 700px) {
  .project-details {
    .right {
      .project-image {
        max-height: 450px;
        margin-bottom: 20px;
      }
    }
  }
}

// may choose to add this if summary becomes too wide

// @media (max-width: 400px) {
//   .summary {
//     flex-direction: column;
//   }
