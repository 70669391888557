@import "../../global.scss";

.quickbili {
  background-color: white;
  padding-top: 30px;

  .header {
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .description {
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 10px;
  }
}
