.image {
  display: block;
  margin-left: auto;
  margin-right: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  width: 95%;
  transform: translate(-50%, -50%);
}

@media (max-width: 970px) {
  .image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
  }
}
