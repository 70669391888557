@import "./global.scss";

.app {
  background-color: $backgroundColor;
  height: 100vh;

  .sections {
    width: 100%;
    height: calc(100vh - 90px);
    background-color: $backgroundColor;
    position: relative;
    top: 90px;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      width: 100vw;
      height: calc(100vh - 90px);
      scroll-snap-align: start;
    }
  }
}

@media (max-height: 700px) {
  .app {
    .sections {
      height: auto;
      scroll-snap-type: none;
      > * {
        height: auto;
        scroll-snap-type: none;
      }
    }
  }
}
