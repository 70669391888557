@import "../../../global.scss";

.paragraph {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  color: $backgroundColor;
  line-height: 1.5;
}

@media (max-width: 880px) {
}
