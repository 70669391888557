@import "../../../global.scss";

.about-details {
  background-color: white;
  display: flex;

  .left {
    flex: 0.5;
    padding-left: 8%;
    padding-right: 7%;
    padding-top: 5%;
    color: $mainColor;

    .description {
      padding-top: 15px;
      font-size: 17px;
      color: $backgroundColor;
      line-height: 1.5;
    }

    .summary {
      flex: 0.4;
      padding-top: 10px;
      padding-bottom: 20px;

      .header {
        color: $mainColor;
        font-size: 20px;
        padding-top: 15px;
      }

      .subtext {
        padding-top: 5px;
        text-decoration: none;
        color: black;
        overflow: wrap;

        background-image: linear-gradient(
          to right,
          $mainColor,
          $mainColor 50%,
          #000 50%
        );

        background-size: 200% 100%;
        background-position: -100%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: all 0.3s ease-in-out;
      }

      .subtext:before {
        content: "";
        background: $mainColor;
        display: block;
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 3px;
      }

      .subtext:hover {
        background-position: 0;
      }

      .subtext:hover::before {
        width: 100%;
      }
    }

    .resume-button {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;

      border: none;
      background-color: $mainColor;

      padding: 10px 15px;
      border-radius: 20px;
      width: 200px;

      color: $plainFontColor;
      font-family: inherit;
      font-size: 15px;
      text-decoration: none;

      transition: 0.2s ease-in-out;

      a {
        text-decoration: none;
        color: $plainFontColor;
      }
    }

    .resume-button:hover {
      opacity: 0.9;
      transform: translateY(-2px);
    }
  }

  .right {
    flex: 0.5;
    padding-top: 5%;
    padding-right: 8%;

    .about-details-image {
      max-height: 90%;

      display: block;
      position: relative;
      margin-left: auto;
      margin-right: 0;
    }
  }
}

@media (max-width: 1100px) {
  .about-details {
    .left {
      flex: 1;
      margin-top: 40px;
    }

    .right {
      flex: 0;
      padding-right: 0%;

      .about-details-image {
        display: none;
      }
    }
  }
}

@media (max-width: 600px) {
  .left {
    text-align: center;
  }
}

@media (max-height: 700px) {
  .about-details {
    .right {
      .about-details-image {
        max-height: 450px;
        margin-bottom: 20px;
      }
    }
  }
}
