@import "../../global.scss";

.navbar {
  width: 100%;
  height: 90px;
  background-color: $backgroundColor;
  color: $mainColor;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;

  .wrapper {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      .logo {
        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
      }
    }

    .right {
      .navitems {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          list-style: none;
          padding: 0 10px;
          position: relative;

          a {
            text-decoration: none;
            font-size: 1.2rem;
            font-weight: 500;
            color: $plainFontColor;
            transition: 0.2s ease-in-out;
            display: inline-block;
          }

          a:hover {
            color: $mainColor;
            transform: translateY(-2px);
          }
        }
      }
    }
  }

  &.active {
    background-color: $backgroundColor;
    color: $plainFontColor;

    .hamburger {
      span {
        &:first-child {
          background-color: $plainFontColor;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          background-color: $plainFontColor;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .navbar {
    &.active {
      background-color: $mainColor;
    }
  }

  .navitems {
    width: 0px;
    height: 0px;
  }

  .hamburger {
    width: 32px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    span {
      width: 100%;
      height: 3px;
      background-color: $mainColor;
      transform-origin: left;
      transition: all 1s ease;
    }
  }
}
