@import "../../global.scss";

.fun {
  background-color: $backgroundColor;
  text-align: center;
  height: auto;

  .text {
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    margin-bottom: 20px;

    h1 {
      color: $mainColor;
      margin-bottom: 10px;
    }

    p {
      color: $plainFontColor;
    }
  }

  .gallery {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;

    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0px 12px;

    padding-bottom: 40px;

    .pic {
      max-width: 100%;
      padding-top: 12px;
    }

    .caption {
      text-align: left;
      color: $plainFontColor;
      padding-top: 5px;
    }
  }
}

@media (max-width: 991px) {
  .fun {
    .gallery {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
  }
}

@media (max-width: 500px) {
  .fun {
    .gallery {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      -webkit-column-width: 100%;
      -moz-column-width: 100%;
      column-width: 100%;
    }
  }
}
