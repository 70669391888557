@import "../../../global.scss";

.learn-more-button {
  border: none;
  background-color: $backgroundColor;

  padding: 6px 15px;
  margin-bottom: 20px;
  border-radius: 20px;

  color: $plainFontColor;
  font-family: inherit;
  font-size: 15px;
  text-decoration: none;

  transition: 0.2s ease-in-out;

  // refers to Link component ('Link' does not work)
  a {
    text-decoration: none;
    color: $plainFontColor;
  }
}

.learn-more-button:hover {
  background-color: $backgroundColor;
  opacity: 0.9;
  transform: translateY(-2px);
}
