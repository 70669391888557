@import "../../../global.scss";

.menu {
  width: 300px;
  height: 100vh;
  background-color: $mainColor;
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 30px;
    font-weight: 300;
    color: $plainFontColor;
    width: 60%;

    li {
      margin-bottom: 20px;

      &:hover {
        font-weight: 600;
      }

      a {
        font-size: inherit;
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &.active {
    right: -300;
  }
}

@media (max-width: 1100px) {
  .menu {
    &.active {
      right: 0px;
    }
  }
}
