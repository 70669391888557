@import "../../global.scss";

.minor-projects {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $backgroundColor;
  text-align: center;
  height: auto;

  .intro {
    color: $plainFontColor;
    margin-bottom: 20px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .gallery {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;

    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
    column-width: 50%;
    padding: 0px 12px;

    .card {
      max-width: 100%;
      padding-bottom: 12px;
    }
  }
}

@media (max-width: 900px) {
  .minor-projects {
    .gallery {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      -webkit-column-width: 100%;
      -moz-column-width: 100%;
      column-width: 100%;
    }
  }
}

@media (max-width: 675px) {
  .minor-projects {
    .intro {
      font-size: 26px;
    }
  }
}

@media (max-width: 575px) {
  .minor-projects {
    .intro {
      font-size: 22px;
    }
  }
}
