@import "../../global.scss";

.project {
  background-color: white;
  color: $backgroundColor;
  display: flex;

  .left {
    flex: 0.6;
    position: relative;
    overflow-y: hidden;
  }

  .right {
    flex: 0.4;
  }
}

@media (max-width: 970px) {
  .project {
    flex-direction: column;
    height: 700px;

    .left {
      flex: 0.6;
    }

    .right {
      flex: 0.4;
      position: relative;
    }
  }
}

@media (max-width: 600px) {
  .project {
    height: 600px;
  }
}

@media (max-width: 450px) {
  .project {
    height: 550px;

    .left {
      flex: 0.5;
    }

    .right {
      flex: 0.5;
    }
  }
}
