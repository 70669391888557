@import "../../../global.scss";

.project-card {
  background-color: white;
  border-radius: 20px;

  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;

  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 50px;

  transition: 0.5s;

  .title {
    font-size: 35px;
    font-weight: 600;
  }

  .tags {
    padding-top: 5px;
    font-size: 20px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.4);
  }

  .description {
    font-size: 20px;
    padding: 10px 0px;
    overflow: wrap;
    font-weight: 350;
    line-height: 150%;
  }

  .image {
    max-width: 90%;
    // max-height: 90%;
  }
}

// .project-card:hover {
//   transform: translate3D(0, -1px, 0) scale(1.03);
// }

@media (max-width: 600px) {
  .project-card {
    .title {
      font-size: 26px;
    }

    .tags {
      font-size: 16px;
    }

    .description {
      font-size: 16px;
    }
  }
}
