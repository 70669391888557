@import "../../../global.scss";

.project-tag {
  background-color: $mainColor;
  height: 25px;
  margin-right: 15px;

  border: none;

  padding: 6px 15px;
  border-radius: 20px;

  color: $plainFontColor;
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}
